import React from "react";

function ExportStoreRank({ answers }) {
  let rank = 0;

  return (
    <div className="p-5">
      <div className="d-flex justify-content-between">
        <img width="100px" src="/assets/images/rndfinals.png" />
        <div className="text-center">
          <h6 className="mb-0">FIGARO COFFEE SYSTEM, INC.</h6>
          <h6 className="mb-0">QUALITY ASSURANCE DEPARTMENT</h6>
          <h6 className="mb-0">ANGEL'S PIZZA STORES - PRODUCT QUALITY AUDIT</h6>
          <h6 className="mb-0">STORE RANKING FOR THE MONTH OF DECEMBER 2022</h6>
        </div>
        <img width="100px" src="/assets/images/rndfinals.png" />
      </div>
      <table className="table table-striped">
        <tr className="text-center">
          <th>RANKING</th>
          <th>STORES</th>
          <th>Product Quality Audit (1st)</th>
          <th>Product Quality Audit (2nd)</th>
          <th>Angel's Pizza Store Audit</th>
          <th>AVERAGE</th>
          <th>RATING</th>
          <th>STORE MANAGER</th>
        </tr>
        {answers.map((answer, index) => {
          const previous = answers[index - 1];
          const {
            "Product Quality Audit (1st)": product1,
            "Product Quality Audit (2nd)": product2,
            "Store Audit": outlet,
            ...form
          } = answer.form;

          const product1Average =
            ((product1 && product1.score / product1.total) || 0) * 100;
          const product2Average =
            ((product2 && product2.score / product2.total) || 0) * 100;
          const outletAverage =
            ((outlet && outlet.score / outlet.total) || 0) * 100;

          //computation for final Average
          const averages = [outletAverage, product1Average, product2Average];

          // Remove any zeros from the array
          const nonZeroAverages = averages.filter((avg) => avg !== 0);

          let TotalAverage;

          switch (nonZeroAverages.length) {
            case 0:
              TotalAverage = 0;
              break;
            case 1:
              TotalAverage = nonZeroAverages[0];
              break;
            case 2:
              TotalAverage = (nonZeroAverages[0] + nonZeroAverages[1]) / 2;
              break;
            case 3:
              TotalAverage =
                (nonZeroAverages[0] + nonZeroAverages[1] + nonZeroAverages[2]) /
                3;
              break;
            // case 4:
            //   TotalAverage =
            //     (nonZeroAverages[0] +
            //       nonZeroAverages[1] +
            //       nonZeroAverages[2] +
            //       nonZeroAverages[3]) /
            //     4;
            //   break;
            default:
              // Use all four averages to calculate total average
              TotalAverage =
                (outletAverage + product1Average + product2Average) / 3;
              break;
          }

          //
          if (previous && previous.average === answer.average) {
            rank--;
          }
          rank++;
          return (
            <tr className="text-center">
              <td>{rank}</td>
              <td>{answer.name}</td>
              <td>{product1Average.toFixed(2)}</td>
              <td>{product2Average.toFixed(2)}</td>
              <td>{outletAverage.toFixed(2)}</td>
              <td>{TotalAverage.toFixed(2)}</td>
              <td>
                {answer.average >= 92.5 && (
                  <span className="badge bg-primary">PASS</span>
                )}
                {answer.average < 92.5 && (
                  <span className="badge bg-danger">FAIL</span>
                )}
              </td>
              <td>-</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default ExportStoreRank;
