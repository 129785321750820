import BasePage from "../../base/BasePage";
import FormAnswersPresenter from "./FormAnswersPresenter";
import NavBar from "../../components/NavBar";
import { Button, InfiniteScroll } from "nq-component";
import { dialog } from "nq-component";
import withRouter from "../../withRouter";
import SelectList from "../../components/SelectList";
import { findObjectUseCase, deleteObjectUseCase } from "../../usecases/object";
import dateFormat from "../../dateFormat";
import { Progress } from "nq-component";
import React from "react";
import DateRange from "../../components/DateRange";
import InputFactory from "../../components/InputFactory";

class FormAnswersPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      forms: [],
      answers: [],
      brands: null,
      brand: null,
      store: null,
      progress: false,
      count: 0,
    };
    this.presenter = new FormAnswersPresenter(
      this,
      findObjectUseCase(),
      deleteObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onChangeForm(value) {
    dialog.close();
    this.presenter.onSelectForm(value);
  }

  onChangeCancel() {
    dialog.close();
  }

  setForms(forms) {
    this.setState({ forms });
  }

  showForms() {
    const forms = this.state.forms;
    dialog.fire({
      component: (
        <SelectList
          onClickItem={this.onChangeForm.bind(this)}
          multiple
          label="Select Form"
          options={forms}
          onClickCancel={this.onChangeCancel.bind(this)}
        />
      ),
      footer: false,
    });
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  setAnswers(answers) {
    this.setState({ answers });
  }

  onClickAnswer(answer) {
    this.presenter.onClickAnswer(answer);
  }

  onDeleteAnswer(event, answer) {
    event.stopPropagation();
    this.presenter.onDeleteAnswer(answer);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeStore(value) {
    console.log("STE", value);
    this.presenter.onChangeStore(value);
  }

  onChangeBrand(value) {
    this.presenter.onChangeBrand(value);
  }

  getBrand() {
    return this.state.brand;
  }

  setBrand(value) {
    this.setState({ brand: value });
  }

  setStore(value) {
    this.setState({ store: value });
  }

  setBrands(value) {
    this.setState({ brands: value });
  }

  setCount(count) {
    this.setState({ count });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  render() {
    const answers = this.state.answers;
    const roles = this.getCurrentRoles();
    const brand = this.state.brand;
    const store = this.state.store;
    const brands = this.state.brands;
    const progress = this.state.progress;
    const count = this.state.count;

    console.log("Storesss", store);
    console.log("answers", answers);

    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container overflow-auto">
          <div className="p-3 px-lg-5 py-lg-4">
            <h2 className="text-capitalize mt-3 m-0">RND Audit</h2>
            <form className="mt-3">
              <div className="d-flex">
                <div className="input-group w-auto">
                  <i className="input-group-text bi bi-shop" />
                  <InputFactory
                    label="All Brands"
                    field="brand"
                    object={{}}
                    type="Pointer"
                    target="brands"
                    onChange={this.onChangeBrand.bind(this)}
                  />
                </div>

                <div className="input-group w-auto ml-5">
                  {brands?.map((_brand) => {
                    if (_brand.id !== brand) {
                      return;
                    }
                    return (
                      <>
                        <i className="input-group-text bi bi-shop" />
                        <InputFactory
                          label="All Stores"
                          field="store"
                          object={{}}
                          type="Pointer"
                          target="stores"
                          // where={{ brand: { id: _brand.id } }}
                          onChange={this.onChangeStore.bind(this)}
                        />
                      </>
                    );
                  })}
                  {/* {!brand && (
                    <>
                      <i className="input-group-text bi bi-shop ml-5" />
                      <InputFactory
                        label="All Stores"
                        field="store"
                        object={{}}
                        type="Pointer"
                        target="stores"
                        onChange={this.onChangeStore.bind(this)}
                      />
                    </>
                  )} */}
                </div>
              </div>
            </form>
            <form className="mt-3">
              <div className="d-flex justify-content-end">
                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>
            </form>
            {this.state.progress && <Progress />}

            <InfiniteScroll
              className="h-100 mt-2"
              useWindow={false}
              loadMore={this.loadMore.bind(this)}
              hasMore={!progress && count > answers.length}
            >
              {/* {!store && (
                <div className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
                  <img width={200} src="/assets/images/choosefirst.png"></img>
                  <h3>To proceed, please select a brand and a store first</h3>
                </div>
              )} */}

              {!store ? (
                <div className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
                  <img width={200} src="/assets/images/choosefirst.png"></img>
                  <h3>To proceed, please select a brand and a store first</h3>
                </div>
              ) : (
                answers?.map((answer) => (
                  <div
                    onClick={this.onClickAnswer.bind(this, answer)}
                    className="bg-white p-3 px-lg-5 py-lg-4 shadow-sm rounded-lg mt-3 cursor-pointer"
                  >
                    {answer.average >= 92.5 && (
                      <div className="d-flex w-fit justify-content-between">
                        <div>
                          <span
                            className="badge rounded-3xl"
                            style={{ backgroundColor: "#362FD9" }}
                          >
                            PASS
                          </span>
                        </div>
                        <div
                          className={roles?.admin ? "" : "d-none"}
                          onClick={(event) =>
                            this.onDeleteAnswer(event, answer)
                          }
                        >
                          <i className="bi bi-trash"></i>
                        </div>
                      </div>
                    )}
                    {answer.average < 92.5 && (
                      <div className="d-flex w-fit justify-content-between">
                        <div>
                          <span className="badge bg-danger rounded-3xl">
                            FAIL
                          </span>
                        </div>
                        <div
                          className={roles?.admin ? "" : "d-none"}
                          onClick={(event) =>
                            this.onDeleteAnswer(event, answer)
                          }
                        >
                          <i className="bi bi-trash"></i>
                        </div>
                      </div>
                    )}
                    <h5 className="mt-2">{answer.form.name}</h5>
                    <h6 className="mt-2 text-primary">{answer.store.name}</h6>
                    <div className="d-flex">
                      <p className="fs-sm">
                        <span className="badge bg-primary me-1">
                          {dateFormat(answer.createdAt)}
                        </span>
                      </p>
                      <p className="fs-sm">
                        {answer.average.toFixed(2) >= 92.5 && (
                          <span
                            className="badge me-1 ms-3"
                            style={{ backgroundColor: "#362FD9" }}
                          >
                            {answer.average.toFixed(2)}%
                          </span>
                        )}
                        {answer.average.toFixed(2) < 92.5 && (
                          <span
                            className="badge  me-1 ms-3"
                            style={{ backgroundColor: "#FF0000" }}
                          >
                            {answer.average.toFixed(2)}
                          </span>
                        )}
                        QA Result
                      </p>
                    </div>
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="shadow-lg rounded-pill"
            onClick={this.onClickAdd.bind(this)}
            style={{ width: "50px", height: "50px" }}
          >
            <i className="bi bi-plus-lg" />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(FormAnswersPage);
