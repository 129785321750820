import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { getCurrentUserUseCase, signOutUseCase } from "../../domain/user";
import withContext from "../../withContext";
import { Routes, Route } from "react-router-dom";
import BasePage from "../../base/BasePage";
import { Layout, OffCanvas, Menu, Progress } from "nq-component";
import getProfile from "../../getProfile";
import FormPage from "../form/FormPage";
import DashboardPage from "../dashboard/DashboardPage";
import TrainingPage from "../training/TrainingPage";
import TrainingDetailsPage from "../training-details/TrainingDetailsPage";
import AccountPage from "../account/AccountPage";
import SupportFormPage from "../support-form/SupportFormPage";
import AnnouncementPage from "../announcement/AnnouncementPage";
import AnnouncementDetailsPage from "../announcement-details/AnnouncementDetailsPage";
import ChatPage from "../chat/ChatPage";
import MessagePage from "../chat-message/MessagePage";
import ChatUserPage from "../chat-user/ChatUserPage";
import SupportChatPage from "../support-chat/SupportChatPage";
import CreateNewTicketPage from "../support/create-new-ticket/CreateNewTicketPage";
import SupportPage from "../support/SupportPage";
import FormAnswersPage from "../form-answers/FormAnswersPage";
import FormAnswerPage from "../form-answer/FormAnswerPage";
import { getSchemasUseCase } from "../../usecases/schema";
import StoreRankPage from "../store-rank/StoreRankPage";
import AnnouncementFormPage from "../announcement-form/AnnouncementFormPage";
import TrainingFormPage from "../training-form/TrainingFormPage";
import TrainingAdminPage from "../training-dashboard/TrainingAdminPage";
import TrainingEdit from "../training-edit/TrainingEdit";
import TrainingExamPage from "../training-examboard/TrainingExamPage";
import TrainingExamCreate from "../training-examcreate/TrainingExamCreate";
import TrainingExamView from "../training-examview/TrainingExamView";
import TrainingVideosPage from "../training-videos/TrainingVideosPage";
import TrainingVideoEdit from "../training-videos/TrainingVideoEdit";
import TrainingVideoForm from "../training-videos/TrainingVideoForm";

const settings = [
  {
    name: "Edit Account",
    route: "/account",
    icon: "bi bi-person-check",
  },
  // {
  //     name: "Notification",
  //     route: "/notification",
  //     icon: "bi bi-bell"
  // },
];

const training = [
  {
    name: "Training",
    route: "/training/videos",
    icon: "bi bi-file-play-fill",
  },
  {
    name: "Training Videos",
    route: "/training/video-links",
    icon: "bi bi-file-play-fill",
    access: ["role:Admin"],
  },
  {
    name: "Training Dashboard",
    route: "/training/dashboard",
    icon: "bi bi-file-play-fill",
    access: ["role:Admin"],
  },
  {
    name: "Exam",
    route: "/training/exam",
    icon: "bi bi-file-play-fill",
  },
  {
    name: "Exam Dashboard",
    route: "/training/examboard",
    icon: "bi bi-file-play-fill",
  },
];

const menusAdmin = [
  {
    name: "Dashboard",
    icon: "bi bi-house",
    route: "/dashboard",
    access: ["role:Admin"],
  },
  {
    name: "RND Audit",
    icon: "bi bi-card-checklist",
    route: "/answers",
  },
  {
    name: "Store Rank",
    icon: "bi bi-shop",
    route: "/rank",
  },
  // {
  //   name: "Supports",
  //   icon: "bi bi-headset",
  //   route: "/support",
  // },
  // {Training
  //   name: "Training",
  //   icon: "bi bi-person-video3",
  //   route: training,
  // },
  // {
  //   name: "Announcement",
  //   icon: "bi bi-megaphone",
  //   route: "/announcement",
  // },
  // {
  //   name: "Chat",
  //   icon: "bi bi-chat",
  //   route: "/chat",
  // },
  {
    name: "Settings",
    icon: "bi bi-sliders",
    route: settings,
  },
];

const menus = [
  {
    name: "Dashboard",
    icon: "bi bi-house",
    route: "/dashboard",
    access: ["role:Admin"],
  },
  // {
  //   name: "Supports",
  //   icon: "bi bi-headset",
  //   route: "/support",
  // },
  // {
  //   name: "Training",
  //   icon: "bi bi-person-video3",
  //   route: training,
  // },
  // {
  //   name: "Announcement",
  //   icon: "bi bi-megaphone",
  //   route: "/announcement",
  // },
  // {
  //   name: "Chat",
  //   icon: "bi bi-chat",
  //   route: "/chat",
  // },
  {
    name: "Settings",
    icon: "bi bi-sliders",
    route: settings,
  },
];

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      signOutUseCase(),
      getSchemasUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  componentWillUnmount() {
    this.presenter.componentWillUnmount();
  }

  signOutClick() {
    this.presenter.signOutClick();
  }

  render() {
    const user = this.getCurrentUser();
    const roles = this.getCurrentRoles();

    if (roles) {
      roles.map((role) => {
        if (
          role.name === "Admin" ||
          role.name === "QA" ||
          role.name === "SOD"
        ) {
          roles.valid = true;
        }
        if (role.name === "Admin") {
          roles.admin = true;
        }
      });
    } else {
      return <Progress />;
    }

    if (user === undefined) {
      return <Progress />;
    }
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas
              className="overflow-hidden"
              onSetShow={value.setCollapse}
              show={value.collapsed}
            >
              <div className="offcanvas-body">
                <nav className="navbar-dark">
                  <div className="text-center p-2">
                    <img
                      width="100px"
                      className="img-fluid rounded-circle img-thumbnail p-0 m-2"
                      src={getProfile(user)}
                    />
                    <div className="p-2 text-white">
                      <h6 className="m-0 text-truncate">{user.name}</h6>
                      <p className="text-truncate m-0">{user.email}</p>
                    </div>
                  </div>
                  <hr className="dropdown-divider bg-white" />
                  <Menu menus={roles.valid ? menusAdmin : menus} />
                </nav>
              </div>
              <div className="my-2">
                <button
                  className="nav-link text-muted btn btn-link"
                  onClick={this.signOutClick.bind(this)}
                >
                  <i className="bi bi-power"></i>
                  <span className="ms-2 fw-bold small">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            <Route exact path={"/form"} element={<FormPage />} />
            <Route exact path={"/"} element={<DashboardPage />} />
            <Route exact path={"/dashboard"} element={<DashboardPage />} />
            <Route exact path={"/support"} element={<SupportPage />} />
            <Route
              exact
              path={"/support/create"}
              element={<SupportFormPage />}
            />
            <Route exact path={"/support/chat"} element={<SupportChatPage />} />
            <Route exact path={"/training/videos"} element={<TrainingPage />} />
            <Route
              exact
              path={"/train/details"}
              element={<TrainingDetailsPage />}
            />

            <Route exact path={"/chat"} element={<ChatPage />} />
            <Route exact path={"/chat/user"} element={<ChatUserPage />} />
            <Route exact path={"/message/:id"} element={<MessagePage />} />
            <Route exact path={"/message"} element={<MessagePage />} />
            <Route
              exact
              path={"/answers"}
              element={roles.valid ? <FormAnswersPage /> : ""}
            />
            <Route
              exact
              path={"/rank"}
              element={roles.valid ? <StoreRankPage /> : ""}
            />
            <Route
              exact
              path={"/form/answer/:formId/:answerId"}
              element={roles.valid ? <FormAnswerPage /> : ""}
            />
            <Route
              exact
              path={"/form/answer/:formId"}
              element={roles.valid ? <FormAnswerPage /> : ""}
            />
            <Route
              exact
              path={"/announcement"}
              element={<AnnouncementPage />}
            />
            <Route
              exact
              path={"/announcement/details"}
              element={<AnnouncementDetailsPage />}
            />
            <Route
              exact
              path={"/announcement/create"}
              element={<AnnouncementFormPage />}
            />
            <Route
              exact
              path={"/training/create"}
              element={<TrainingFormPage />}
            />
            <Route exact path={"/training/edit"} element={<TrainingEdit />} />
            <Route
              exact
              path={"/training/dashboard"}
              element={<TrainingAdminPage />}
            />
            <Route
              exact
              path={"/training/video-links"}
              element={<TrainingVideosPage />}
            />
            <Route
              exact
              path={"/training/videos/edit"}
              element={<TrainingVideoEdit />}
            />
            <Route
              exact
              path={"/training/videos/create"}
              element={<TrainingVideoForm />}
            />
            <Route
              exact
              path={"/training/examboard"}
              element={<TrainingExamPage />}
            />
            <Route
              exact
              path={"/training/examcreate"}
              element={<TrainingExamCreate />}
            />
            <Route
              exact
              path={"/training/examview"}
              element={<TrainingExamView />}
            />
            <Route
              exact
              path={"/training/examview/:examId/:answerId"}
              element={roles.valid ? <TrainingExamView /> : ""}
            />
            <Route
              exact
              path={"/training/examview/:examId"}
              element={roles.valid ? <TrainingExamView /> : ""}
            />

            <Route exact path={"/account"} element={<AccountPage />} />
            <Route
              exact
              path={"/create-new-ticket"}
              element={<CreateNewTicketPage />}
            />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withContext(MainPage);
